<template>
    <section>
        <modal ref="modalEditarCondicion" titulo="Tipo de vendedor" @guardar="updateCondicion">
            <div class="row mx-5 my-2 justify-center">
                <div class="col-10 justify-content-center">
                    <p class="f-13 pl-3 text-general">Seleccione el tipo de vendedor</p>
                    <el-select v-model="model.id_condicion" filterable size="small" class="w-100">
                        <el-option
                        v-for="item in condiciones"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
						:disabled="item.id == model.id_condicion"
                        />
                    </el-select>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import CambioDatos from "~/services/tiendas/cambio_datos";
import Condiciones from "~/services/configurar/admin/tiposVendedor";

export default {
    data(){
        return{
            model: {
                id_condicion: null
            },
            id_cedis: null,
            condiciones: []
        }
    },
    computed:{
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(item){
            this.model.id_condicion = item.id_tipo
            this.id_cedis = item.id_cedis
            this.listarCondiciones()
            this.$refs.modalEditarCondicion.toggle();
        },
        async listarCondiciones(){
            try {
                let params = {
                    id_cedis: this.id_cedis
                }
                const {data} = await Condiciones.getTiposVendedor(params)
                this.condiciones = data.data

            } catch (error){
                this.error_catch(error)
            }
        },
        async updateCondicion(){
            try {
                if(this.model.id_condicion === null){
                    this.notificacion('Mensaje', 'La condición es requerida', 'warning')
                    return false
                }
                const {data} = await CambioDatos.updateCondicion(this.id_tienda,{tipo: this.model.id_condicion})
                this.notificacion('Mensaje', 'Informacion actualizaada', 'success')
                this.$emit('actualizar')
                this.$refs.modalEditarCondicion.toggle()
                this.model.id_condicion = null

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
