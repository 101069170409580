import axios from "axios";

const API = "tipos-vendedores";

const tiposVendedor = {
	getTiposVendedor() {
		return axios(`${API}`)
	},
	postTiposVendedor(payload) {
		return axios.post(`${API}`, payload)
	},
	deleteTipoVendedor(idTipo) {
		return axios.delete(`${API}/${idTipo}`)
	},
};

export default tiposVendedor;
